@import url(variables.scss);

.rsvn-list-group__container {
    --_rsvn-list-group-spacing: 0.5rem;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: var(--_rsvn-list-group-spacing);
    overflow: auto;
}

.rsvn-list-group__parent {
    width: max-content;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    
    .rsvn-list-group__parent-fixed-content {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 5px;
        padding: var(--_rsvn-list-group-spacing);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        gap: var(--_rsvn-list-group-spacing);
        position: sticky;
        left: 0;
        width: 25vw;
        min-width: 15rem;
        // background-color: rgb(208, 227, 233);
        background-color: transparentize($default-border-color, 0.25);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.06);

        .rl-data-wrapper {
            .data {
                // label {
                //     &.rsvn-number {
                //         color: $default-text-color-dark;
                //     }
                // }
                span {
                    &.rsvn-number {
                        font-weight: bold;
                        color: $default-text-color-dark;
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    .rsvn-list-group__parent-scrollable-content {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 5px;
        // background-color: rgb(208, 227, 233);
        background-color: transparentize($default-border-color, 0.5);
        padding: var(--_rsvn-list-group-spacing);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        gap: var(--_rsvn-list-group-spacing);
        // clip-path: inset(0 -100vmax);

        // Last data is used to extend parent container to full width
        .parent-last-data {
            width: calc(
                calc(calc(150px + 10px) * 14)
                + calc(16px * 6)
                - 50px
            );
            // 150px is width of each data and 10px is gap between them & 14 means there are 14 remaining data to equalize the width
            // 16px is size of icon & 6 means there are 6 data with icon [Recalculate if any icon is added]
            // 50px is subtracted because Meal Plan data has less width (only 100px)
        }
    }
}

.rsvn-list-group__item {
    width: max-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-left: 3rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0.85);
    
    &.day-use {
        .rsvn-list-group__fixed-content {
            box-shadow: 4px 0px 0px $default-dashboard-due-out-color;
        }
    }

    &.guest-info {
        border-left: 6px solid #ffffff;

        &.red {
            border-left: 6px solid transparentize($default-red-color, 0.5);
        }
        &.green {
            border-left: 6px solid transparentize($default-green-color, 0.5);
        }
        &.yellow {
            border-left: 6px solid transparentize($default-yellow-color, 0.5);
        }
    }

    .rsvn-list-group__fixed-content {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: var(--_rsvn-list-group-spacing);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        gap: var(--_rsvn-list-group-spacing);
        position: sticky;
        left: 0;
        width: 25vw;
        min-width: 15rem;

        border-left: 6px solid rgba(255, 255, 255, 0.95);
        background-color: rgba(255, 255, 255, 0.95);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.06);

        .guest-detail-container {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 2px;

            .guest-name-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;

                .vip-badge-container{
                    width: 25px;
                    height: 25px;
    
                    svg {
                        filter: drop-shadow(0px 1px 1px #00000020);
                        path, rect {
                            fill: rgb(234, 180, 21);
                        }
                    }
                }
            }

            span {
                &.guest-name {
                    font-size: clamp(1rem, 2vw, 1.25rem);
                    color: $default-text-color-dark;
                    overflow-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-weight: 500;
                }

                &.reservation-number, &.reservation-status, &.day-use {
                    // background-color: transparentize($default-green-color, 0.9);
                    // border: 1px solid transparentize($default-green-color, 0.9);
                    // padding: 5px 6px;
                    color: $default-green-color;
                    white-space: nowrap;
                    border-radius: 4px;
                    font-size: 1rem;
                    font-weight: bold;

                    span {
                        font-size: 1.1rem;
                    }

                    &.reservation-status {
                        color: $default-red-color;
                    }

                    &.day-use {
                        color: $default-dashboard-due-out-color;
                    }
                }
            }
        }

        &.red {
            border-left: 6px solid transparentize($default-red-color, 0.5);
        }
        &.green {
            border-left: 6px solid transparentize($default-green-color, 0.5);
        }
        &.yellow {
            border-left: 6px solid transparentize($default-yellow-color, 0.5);
        }
    }

    .rsvn-list-group__scrollable-content {
        // width: 100%;
        // padding: var(--_rsvn-list-group-spacing);
        // display: grid;
        // grid-template-columns: repeat(20, 1fr);
        // gap: 1rem;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: var(--_rsvn-list-group-spacing);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        gap: var(--_rsvn-list-group-spacing);

        .rl-data-wrapper {
            // grid-column: span 1;
        }
    }

    // &::before {
    //     --__connector-height: 4.4rem;
    //     content: '';
    //     height: var(--__connector-height);
    //     width: 1.5rem;
    //     position: absolute;
    //     left: -2rem;
    //     top: calc(var(--_rsvn-list-group-spacing) * -5.4);
    //     z-index: 1;
    //     border: 1px solid $default-border-color;
    //     border-top: 0;
    //     border-right: 0;
    //     border-bottom-left-radius: 0.5rem;
    // }

    // &::after {
    //     content: '';
    //     height: 0.5rem;
    //     width: 0.5rem;
    //     border-radius: 50%;
    //     background-color: $default-border-color;
    //     position: absolute;
    //     left: -0.75rem;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     z-index: 1;
    // }

    // &.first {
    //     &::before {
    //         --__connector-height: 2.15rem;
    //         top: calc(var(--_rsvn-list-group-spacing) * -0.8);
    //     }
    // }
}


.rl-data-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .icon-wrapper {
        // --_data-icon-size: 25px;
        // width: var(--_data-icon-size);
        // height: var(--_data-icon-size);
        // border-radius: calc(var(--_data-icon-size) * 2);
        // background-color: transparentize($default-border-color, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }

    .data {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        label {
            font-size: 12px;
            line-height: 12px;
            color: $default-text-color-light;
            white-space: nowrap;
        }

        span {
            &.value {
                font-size: 14px;
                line-height: 14px;
                color: $default-text-color-dark;
                font-weight: 500;
                white-space: nowrap;
                text-transform: uppercase;
                min-width: 150px;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;

                &.meal-plan {
                    min-width: 100px;
                    max-width: 100px;
                }
                &.company {
                    min-width: 250px;
                    max-width: 250px;
                }
            }
        }
    }

    &.parent {
        .data {
            // flex-direction: row;
        }
    }
}


.option-container {
    button {
        --_opt-btn-size: 30px;
        width: var(--_opt-btn-size);
        height: var(--_opt-btn-size);
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        border-radius: calc(var(--_opt-btn-size) * 2);
        background-color: transparentize($default-border-color, 0.65);
        color: $default-text-color-light;
    }

    &.rsvn-group-parent {
        button {
            background-color: transparentize(#ffffff, 0.35);
        }
    }
}